import { DateTime } from 'luxon';
import { Component } from 'react';

interface Props {
  date: DateTime;
  past?: string;
}

const refreshInterval = (date: DateTime): number => {
  const seconds = DateTime.utc().diff(date, 'seconds').seconds;
  if (seconds / 2 <= 60) return 1000;
  if (seconds / 2 <= 3600) return (60 * 1000) / 2;
  return (3600 * 1000) / 4;
};

class RelativeDateController extends Component<Props> {
  private refreshHandle: number | undefined;

  public componentWillUnmount(): void {
    if (this.refreshHandle !== undefined) {
      clearInterval(this.refreshHandle);
    }
  }

  public render(): JSX.Element {
    if (this.refreshHandle !== undefined) {
      clearInterval(this.refreshHandle);
    }
    const { date, past } = this.props;

    this.refreshHandle = window.setInterval(() => {
      this.forceUpdate();
    }, refreshInterval(date));
    return (
      <time
        dateTime={date.toISO() ?? ''}
        title={date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) ?? ''}
      >
        {date.toUTC() <= DateTime.utc()
          ? past ?? date.toRelative()
          : date.toRelative()}
      </time>
    );
  }
}

export const RelativeTimestamp = RelativeDateController;
